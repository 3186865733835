import * as React from "react";
import { Button, ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { useStore } from "./store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/login";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Container } from "./components/container";
import { Productions } from "./pages/productions";
import { Production } from "./pages/production";
import { Archive } from "./pages/archive";
import "./app.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Api from "./services/api";
import { ForgotPassword } from "./pages/forgot-password";
import { PasswordReset } from "./pages/password-reset";
import { Document } from "./pages/document";
import { AmbientalPolicy } from "./pages/ambiental-policy";
import Updater from "./components/updater";
import { PwaGuideiOS } from "./pages/pwa-guide-ios";
import { PwaGuideAndroid } from "./pages/pwa-guide-android";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const {
    user,
    setUser,
    setAmbientalPolicy,
    ambientalPolicyFiles,
    setAppInstaller,
    appInstaller,
  } = useStore();

  React.useEffect(() => {
    function appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
    }

    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);
  const loadPolicy = React.useRef(async () => {
    const { data } = await Api.ambientalPolicy();
    setAmbientalPolicy(data.pdf);
  });
  React.useEffect(() => {
    if (user._id && ambientalPolicyFiles?.length === 0) {
      loadPolicy?.current();
    }
  }, [user]);
  React.useEffect(() => {
    Api.on401(() => {
      if (user._id) {
        alert("Sessione scaduta, effettua nuovamente il login");
        setUser({});
      }
    });

    if (!appInstaller) {
      window.addEventListener("beforeinstallprompt", (e: any) => {
        e.preventDefault();
        if (e) {
          setAppInstaller(e);
        }
      });
    } else if (window.matchMedia("(display-mode: standalone)").matches) {
      setAppInstaller(null);
    }
  }, []);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Container>
            <Header />
            <Routes>
              {!user._id && (
                <>
                  <Route path="*" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/password-reset/:token"
                    element={<PasswordReset />}
                  />
                </>
              )}
              {user._id && (
                <>
                  <Route path="/" element={<Productions />} />
                  <Route path="/production/:id" element={<Production />} />
                  <Route path="/document/:url" element={<Document />} />
                  <Route path="/production/:id/:folder" element={<Archive />} />
                </>
              )}
              <Route path="/ambiental-policy" element={<AmbientalPolicy />} />
              <Route path="/pwa-guide-ios" element={<PwaGuideiOS />} />
              <Route path="/pwa-guide-android" element={<PwaGuideAndroid />} />
            </Routes>
            <Updater />
            <Footer />
          </Container>
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};
