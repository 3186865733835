import * as React from "react";
import {
  Box,
  Link,
  Flex,
  Input,
  Checkbox,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { Header } from "../components/header";
import { Container } from "../components/container";
import { Footer } from "../components/footer";
import { Logo } from "../components/logo";
import { useStore } from "../store";
import Api from "../services/api";
import { Link as RouterLink } from "react-router-dom";
import { isAndroid, isIOS } from "../services/utils";

export const Login = () => {
  const {
    setUser,
    setStatuses,
    t,
    firstTime,
    setFirstTime,
    appInstaller,
    setAppInstaller,
  } = useStore();
  const [show, setShow] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(true);
  const handleClick = () => setShow(!show);
  const login = async () => {
    try {
      const { data } = await Api.login(email, password);
      if (remember) {
        setUser({ ...data.user, token: data.access_token });
      } else {
        setUser(data.user);
      }
      const { data: statuses } = await Api.statusesSchema();
      setStatuses(statuses.tableSchema.status.enum);
      Api.setTemporaryToken(data.access_token);
    } catch (e: any) {
      if (e.response.status === 401) {
        alert(t("invalid_credentials"));
      } else {
        alert(t("server_error"));
      }
    }
  };
  if (firstTime) {
    return (
      <Wrapper
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={800}
          height="100%"
          pb="30%"
        >
          <video
            src={"/odg-intro.mp4"}
            autoPlay
            muted={true}
            controls={false}
            playsInline
            preload="auto"
            onEnded={() => setFirstTime(false)}
          ></video>

          <Link display={["block", "none"]} onClick={() => setFirstTime(false)}>
            {t("skip_intro")}
          </Link>
        </Flex>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        height="100%"
        pb="30%"
      >
        <Logo mb="48px" />
        <Input
          placeholder="E-mail"
          mb="24px"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          value={email}
        />
        <InputGroup size="md" mb="24px">
          <Input
            type={show ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Password"
          />
          <InputRightElement>
            <Box cursor="pointer" onClick={handleClick}>
              {show ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15"
                  viewBox="0 0 22 15"
                >
                  <path
                    opacity="0.502"
                    d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                    transform="translate(-1 -4)"
                    fill="#191919"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="visibility_black_24dp" opacity="0.502">
                    <path
                      id="Tracciato_19"
                      data-name="Tracciato 19"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <path
                      id="Tracciato_20"
                      data-name="Tracciato 20"
                      d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                      transform="translate(0 1)"
                      fill="#191919"
                    />
                    <line
                      id="Linea_33"
                      data-name="Linea 33"
                      y1="18"
                      x2="18"
                      transform="translate(3.5 3.5)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              )}
            </Box>
          </InputRightElement>
        </InputGroup>
        <Flex justifyContent="space-between" width="100%" mb="24px">
          <Box>
            <Checkbox
              colorScheme="brandGreen"
              isChecked={remember}
              onChange={(e) => setRemember(e.target.checked)}
            >
              {t("remember_me")}
            </Checkbox>
          </Box>
          <Box>
            <Link>
              <RouterLink to="/forgot-password">
                {t("password_forgotten_question")}
              </RouterLink>
            </Link>
          </Box>
        </Flex>
        <Flex wrap="wrap" justifyContent="center">
          <Button variant="solid" size="md" onClick={login} m="10px">
            {t("login")}
          </Button>
          {!window.matchMedia("(display-mode: standalone)").matches &&
            appInstaller && (
              <Button
                variant="solid"
                size="md"
                onClick={() => {
                  appInstaller.prompt();
                  appInstaller.userChoice.then((choiceResult: any) => {
                    if (choiceResult.outcome === "accepted") {
                      setAppInstaller(null);
                    }
                  });
                }}
                m="10px"
              >
                {t("install_app")}
              </Button>
            )}
          {!window.matchMedia("(display-mode: standalone)").matches &&
            !appInstaller &&
            isIOS && (
              <RouterLink to="/pwa-guide-ios">
                <Button variant="solid" size="md" m="10px">
                  {t("install_app_guide")}
                </Button>
              </RouterLink>
            )}
          {!window.matchMedia("(display-mode: standalone)").matches &&
            !appInstaller &&
            isAndroid && (
              <RouterLink to="/pwa-guide-android">
                <Button variant="solid" size="md" m="10px">
                  {t("install_app_guide")}
                </Button>
              </RouterLink>
            )}
        </Flex>
      </Flex>
    </Wrapper>
  );
};
