import * as React from "react";
import { Box, Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { useParams } from "react-router-dom";
import { useStore } from "../store";
import { Document as DocumentPDF, Page, pdfjs } from "react-pdf";
import { useWindowSize } from "../hooks/use-window-size";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Document = () => {
  const { t } = useStore();
  const { url } = useParams<{ url: string }>();
  const [numPages, setNumPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [loaded, setLoaded] = React.useState(false);
  const size = useWindowSize();
  if (!url) return null;
  const extensionPaths = url.split(".");
  const extension = extensionPaths[extensionPaths.length - 1];
  const urlPaths = url.split("/");
  const fileName = urlPaths[urlPaths.length - 1];

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
    setLoaded(true);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  if (extension === "pdf") {
    return (
      <>
        {loaded && (
          <Wrapper>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                size="sm"
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                <Box mr="10px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="14.828"
                    viewBox="0 0 22 14.828"
                  >
                    <g
                      id="Raggruppa_73"
                      data-name="Raggruppa 73"
                      transform="translate(1 1.414)"
                    >
                      <path
                        id="Tracciato_18"
                        data-name="Tracciato 18"
                        d="M6,12,0,6,6,0"
                        transform="translate(0)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <line
                        id="Linea_14"
                        data-name="Linea 14"
                        x2="20"
                        transform="translate(0 6)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </Box>
                {t("page_previous")}
              </Button>
              <Text mx="10px" textAlign="center">
                {t("page")} {pageNumber} {t("page_of")} {numPages}
              </Text>
              <Button
                size="sm"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                _active={{ backgroundColor: "#005802" }}
                _hover={{ backgroundColor: "#005802" }}
                _focus={{ backgroundColor: "#005802" }}
              >
                {t("page_next")}
                <Box ml="10px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="14.828"
                    viewBox="0 0 22 14.828"
                  >
                    <g
                      id="Raggruppa_73"
                      data-name="Raggruppa 73"
                      transform="translate(21 13.414) rotate(180)"
                    >
                      <path
                        id="Tracciato_18"
                        data-name="Tracciato 18"
                        d="M6,12,0,6,6,0"
                        transform="translate(0)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <line
                        id="Linea_14"
                        data-name="Linea 14"
                        x2="20"
                        transform="translate(0 6)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </Box>
              </Button>
            </Flex>
          </Wrapper>
        )}
        <Center>
          <DocumentPDF
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Spinner size="xl" color="brandGreen" />}
          >
            <Page width={size.width * 0.9} pageNumber={pageNumber} />
          </DocumentPDF>
        </Center>
      </>
    );
  }
  return (
    <Wrapper>
      <Text fontWeight="semibold" mb="20px">
        {fileName}
      </Text>
      <video width="100%" height="auto" controls>
        <source src={url} />
      </video>
    </Wrapper>
  );
};
