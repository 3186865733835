import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { LanguageSelector } from "./language-selector";
import { useStore } from "../store";
import { Wrapper } from "./wrapper";
import { useNavigate, useLocation } from "react-router-dom";

export const Header = () => {
  const { user, setUser, lang, setLang, t, firstTime } = useStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const logout = () => {
    setUser({});
  };

  if (firstTime) return null;
  if (!user._id && pathname === "/")
    return (
      <Flex justifyContent="space-between" p={["40px 20px", "32px 48px"]}>
        <Flex alignItems="center">
          <Box mr="10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
            >
              <path
                d="M16.571,1.928a14,14,0,1,0,14,14,14,14,0,0,0-14-14Zm0,26.25a12.209,12.209,0,0,1-4.846-1L18.1,20.009a.875.875,0,0,0,.221-.581V16.8a.875.875,0,0,0-.875-.875c-3.09,0-6.349-3.212-6.381-3.244a.875.875,0,0,0-.619-.256h-3.5a.875.875,0,0,0-.875.875v5.25a.875.875,0,0,0,.484.783l3.016,1.508v5.138a12.257,12.257,0,0,1-4.07-15.3H8.7a.875.875,0,0,0,.619-.256l3.5-3.5a.875.875,0,0,0,.256-.619V4.186a12.284,12.284,0,0,1,8.867.728c-.113.1-.224.2-.329.3a5.251,5.251,0,0,0,3.709,8.963q.13,0,.26-.006a20.548,20.548,0,0,1-.23,10.181.872.872,0,0,0-.023.143,12.212,12.212,0,0,1-8.754,3.682Z"
                transform="translate(-2.571 -1.928)"
                fill="#005802"
                opacity="0.754"
              />
            </svg>
          </Box>

          <Text>There is no planet B</Text>
        </Flex>
        <Flex>
          <LanguageSelector lang={lang} onSelect={setLang} />
        </Flex>
      </Flex>
    );

  return (
    <Wrapper>
      <Flex justifyContent="space-between" py={["20px", "48px"]}>
        {pathname === "/" && (
          <Flex alignItems="center">
            <Box mr="5px">
              <svg
                id="person_black_24dp"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Tracciato_34"
                  data-name="Tracciato 34"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Tracciato_35"
                  data-name="Tracciato 35"
                  d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,10c2.7,0,5.8,1.29,6,2H6c.23-.72,3.31-2,6-2M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,10c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
                  fill="#191919"
                />
              </svg>
            </Box>
            {user.firstName} {user.lastName}
          </Flex>
        )}
        {pathname !== "/" && (
          <Flex
            cursor="pointer"
            onClick={() => navigate(-1)}
            alignItems="center"
          >
            <Box mr="10px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="14.828"
                viewBox="0 0 22 14.828"
              >
                <g
                  id="Raggruppa_73"
                  data-name="Raggruppa 73"
                  transform="translate(1 1.414)"
                >
                  <path
                    id="Tracciato_18"
                    data-name="Tracciato 18"
                    d="M6,12,0,6,6,0"
                    transform="translate(0)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Linea_14"
                    data-name="Linea 14"
                    x2="20"
                    transform="translate(0 6)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </Box>
            {t("back")}
          </Flex>
        )}

        {user._id && (
          <Box cursor="pointer" onClick={logout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g
                id="Raggruppa_135"
                data-name="Raggruppa 135"
                transform="translate(-344 -59)"
              >
                <g
                  id="Rettangolo_179"
                  data-name="Rettangolo 179"
                  transform="translate(344 59)"
                  fill="none"
                  stroke="#005802"
                  strokeWidth="1"
                  opacity="0"
                >
                  <rect width="24" height="24" stroke="none" />
                  <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <g
                  id="Raggruppa_4"
                  data-name="Raggruppa 4"
                  transform="translate(37 -435)"
                >
                  <path
                    id="Tracciato_1"
                    data-name="Tracciato 1"
                    d="M-366.052,358.307l-4,4,4,4"
                    transform="translate(-42.052 868.306) rotate(180)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Linea_2"
                    data-name="Linea 2"
                    x1="10"
                    transform="translate(318 506)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Linea_6"
                    data-name="Linea 6"
                    x1="6"
                    transform="translate(310 498)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Linea_8"
                    data-name="Linea 8"
                    y2="16"
                    transform="translate(310 498)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Linea_7"
                    data-name="Linea 7"
                    x1="6"
                    transform="translate(310 514)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </Box>
        )}
      </Flex>
    </Wrapper>
  );
};
