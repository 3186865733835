export function getDateString(day: string) {
  try {
    const d = new Date(day);
    if (d instanceof Date && !isNaN(d as any)) {
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }
    return day;
  } catch (e) {
    return day;
  }
}

export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const isAndroid = /(android)/i.test(navigator.userAgent);
