import * as React from "react";
import {
  Box,
  Link,
  Flex,
  Input,
  Checkbox,
  Button,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import Api from "../services/api";
import { useParams } from "react-router-dom";
import { useStore } from "../store";

export const ForgotPassword = () => {
  const { t } = useStore();
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const forgotPassword = async () => {
    try {
      await Api.forgotPassword(email);
      setMessage(t("reset_password_success"));
    } catch (e) {
      setMessage(t("reset_password_error"));
    }
  };
  return (
    <Wrapper flex="1">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        height="100%"
        pb="40%"
      >
        <Text
          alignSelf="flex-start"
          fontSize="14px"
          mb="24px"
          color="rgba(25,25,25,.75)"
        >
          {t("password_reset")}
        </Text>
        {message && (
          <Text
            alignSelf="flex-start"
            fontSize="14px"
            mb="24px"
            color="rgba(25,25,25,.75)"
          >
            {message}
          </Text>
        )}
        {!message && (
          <>
            <Input
              placeholder="E-mail"
              mb="24px"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Button variant="solid" size="md" onClick={forgotPassword}>
              {t("send_recovery_link")}
            </Button>
          </>
        )}
      </Flex>
    </Wrapper>
  );
};
