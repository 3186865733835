import * as React from "react";
import {
  Box,
  Link,
  Flex,
  Input,
  Checkbox,
  Button,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import Api from "../services/api";
import { useStore } from "../store";
import { useParams } from "react-router-dom";

export const PasswordReset = () => {
  const { t } = useStore();
  const [message, setMessage] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { token } = useParams<{ token: string }>();
  const resetPassword = async () => {
    try {
      if (!token) {
        return alert("Token non valido");
      }
      if (password === "") {
        return alert("La password non è valida");
      }
      if (password !== passwordRepeat) {
        return alert("Le password non coincidono");
      }
      await Api.resetPassword(password, token);
      setMessage(
        "Password cambiata con successo. Torna indietro per effettuare il login."
      );
    } catch (e) {
      setMessage(
        "C'é stato un problema nell'invio del link, contatta l'assistenza."
      );
    }
  };
  return (
    <Wrapper flex="1">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        height="100%"
        pb="40%"
      >
        <Text
          alignSelf="flex-start"
          fontSize="14px"
          mb="24px"
          color="rgba(25,25,25,.75)"
        >
          {t("password_forgot")}
        </Text>
        {message && (
          <Text
            alignSelf="flex-start"
            fontSize="14px"
            mb="24px"
            color="rgba(25,25,25,.75)"
          >
            {message}
          </Text>
        )}
        {!message && (
          <>
            <InputGroup size="md" mb="24px">
              <Input
                type={show ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Password"
              />
              <InputRightElement>
                <Box cursor="pointer" onClick={handleClick}>
                  {show ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="15"
                      viewBox="0 0 22 15"
                    >
                      <path
                        opacity="0.502"
                        d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                        transform="translate(-1 -4)"
                        fill="#191919"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="visibility_black_24dp" opacity="0.502">
                        <path
                          id="Tracciato_19"
                          data-name="Tracciato 19"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Tracciato_20"
                          data-name="Tracciato 20"
                          d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                          transform="translate(0 1)"
                          fill="#191919"
                        />
                        <line
                          id="Linea_33"
                          data-name="Linea 33"
                          y1="18"
                          x2="18"
                          transform="translate(3.5 3.5)"
                          fill="none"
                          stroke="#191919"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  )}
                </Box>
              </InputRightElement>
            </InputGroup>
            <InputGroup size="md" mb="24px">
              <Input
                type={show ? "text" : "password"}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                value={passwordRepeat}
                placeholder={t("repeat_password")}
              />
              <InputRightElement>
                <Box cursor="pointer" onClick={handleClick}>
                  {show ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="15"
                      viewBox="0 0 22 15"
                    >
                      <path
                        opacity="0.502"
                        d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                        transform="translate(-1 -4)"
                        fill="#191919"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="visibility_black_24dp" opacity="0.502">
                        <path
                          id="Tracciato_19"
                          data-name="Tracciato 19"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Tracciato_20"
                          data-name="Tracciato 20"
                          d="M12,6a9.77,9.77,0,0,1,8.82,5.5,9.822,9.822,0,0,1-17.64,0A9.77,9.77,0,0,1,12,6m0-2A11.827,11.827,0,0,0,1,11.5a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4Zm0,5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,12,9m0-2a4.5,4.5,0,1,0,4.5,4.5A4.507,4.507,0,0,0,12,7Z"
                          transform="translate(0 1)"
                          fill="#191919"
                        />
                        <line
                          id="Linea_33"
                          data-name="Linea 33"
                          y1="18"
                          x2="18"
                          transform="translate(3.5 3.5)"
                          fill="none"
                          stroke="#191919"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  )}
                </Box>
              </InputRightElement>
            </InputGroup>
            <Button variant="solid" size="md" onClick={resetPassword}>
              {t("change_password")}
            </Button>
          </>
        )}
      </Flex>
    </Wrapper>
  );
};
