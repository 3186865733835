import { useStore } from "../store";

export const translate = (obj: any) =>
  obj ? obj[useStore.getState().lang] || obj[Object.keys(obj)[0]] : "";

export const translations = {
  it: {
    back: "Indietro",
    change_password: "Cambia password",
    password_forgot: "PASSWORD DIMENTICATA",
    password_reset: "RESET PASSWORD",
    reset_password: "RESET PASSWORD",
    reset_password_success: "Link di recupero inviato con successo",
    reset_password_error:
      "C'é stato un problema nell'invio del link, contatta l'assistenza.",
    repeat_password: "Ripeti password",
    send_recovery_link: "INVIA LINK DI RECUPERO",
    ambiental_policy_1: "Policy",
    ambiental_policy_2: "Ambientale",
    remember_me: "RICORDAMI",
    password_forgotten_question: "PASSWORD DIMENTICATA?",
    login: "ACCEDI",
    //
    assigned_productions: "PRODUZIONI ASSEGNATE",
    no_productions: "Non hai nessuna produzione assegnata",
    no_documents: "Non ci sono documenti in questa sezione",
    odg_archive: "Archivio ODG",
    PDL: "PDL",
    film_scripts: "Sceneggiatura",
    exerpts: "Stralci",
    skip_intro: "Salta intro",
    invalid_credentials:
      "Credenziali errate, verifica che i dati siano corretti",
    server_error: "Errore del server, riprova più tardi.",
    page: "Pagina",
    page_of: "di",
    page_previous: "Precedente",
    page_next: "Prossima",
    install_app: "INSTALLA APP",
    install_app_guide: "GUIDA INSTALLAZIONE APP",
    update_available:
      "Una nuova versione é disponibile, vuoi aggiornare l'app?",
    pwa_android_guide_title: "Come installare l'app su Android",
    pwa_ios_guide_title: "Come installare l'app su iOS",
    pwa_android_guide_1: "Usa il browser Chrome e seleziona il menu",
    pwa_ios_guide_1: "Usa il browser Safari e seleziona il tasto condividi",
    pwa_android_guide_2: "Premi sull'opzione 'Installa app'",
    pwa_ios_guide_2: "Premi sull'opzione 'Aggiungi alla schermata Home'",
  },
  en: {
    back: "Back",
    change_password: "Change password",
    password_forgot: "PASSWORD FORGOT",
    password_reset: "RESET PASSWORD",
    reset_password: "RESET PASSWORD",
    reset_password_success: "Recovery link sent successfully",
    reset_password_error:
      "There was a problem in sending the link, contact assistance.",
    repeat_password: "Retype password",
    send_recovery_link: "SEND RECOVERY LINK",
    ambiental_policy_1: "Eco",
    ambiental_policy_2: "Policy",
    remember_me: "REMEMBER ME",
    password_forgotten_question: "FORGOT PASSWORD?",
    login: "LOG IN",
    assigned_productions: "ASSIGNED PRODUCTIONS",
    no_productions: "You don't have any assigned production",
    no_documents: "There are no documents in this sectio ",
    odg_archive: "ODG Archive",
    PDL: "PDL",
    film_scripts: "Film scripts",
    exerpts: "Exerpts",
    skip_intro: "Skip intro",
    invalid_credentials:
      "Incorrect credentials, please verify that credentials are correct",
    server_error: "Server error, please try again later.",
    page: "Page",
    page_of: "of",
    page_previous: "Previous",
    page_next: "Next",
    install_app: "INSTALL APP",
    install_app_guide: "INSTALL APP GUIDE",
    update_available:
      "A new version is available, do you want to update the app?",
    pwa_android_guide_title: "How to install the app on Android",
    pwa_ios_guide_title: "How to install the app on iOS",
    pwa_android_guide_1: "Open Chrome browser and select the menu",
    pwa_ios_guide_1: "Open Safari browser and select the Share button",
    pwa_android_guide_2: "Press 'Install app' option",
    pwa_ios_guide_2: "Press the 'Add to Home Screen' option",
  },
};
