import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "40em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

export const theme = extendTheme({
  breakpoints,
  colors: {
    brand: {
      500: "#AC1926",
    },
    brandGreen: {
      500: "#005802",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "800",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "4px",
        fontWeight: "600",
      },
      variants: {
        outline: {
          border: "2px solid",
          borderColor: "brand.500",
        },
        solid: {
          bg: "brandGreen.500",
          color: "white",
        },
      },
      sizes: {
        md: {
          fontSize: "20px",
          height: "56px",
          padding: "12px 35px",
        },
      },
    },
    Input: {
      baseStyle: {
        borderRadius: "4px",
      },
      variants: {
        solid: {
          field: {
            backgroundColor: "#F9F9F9",
          },
        },
      },
      sizes: {
        md: {
          field: {
            fontSize: "16px",
            height: "56px",
          },
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },
    Checkbox: {
      variants: {
        solid: {
          baseStyle: {
            backgroundColor: "#F9F9F9",
          },
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },
    Textarea: {
      baseStyle: {
        borderRadius: "15px",
      },
      variants: {
        solid: {
          borderRadius: "15px",
        },
      },
      sizes: {
        md: {
          field: {
            fontSize: "14px",
            height: "unset",
            padding: "15px 20px",
            borderRadius: "15px",
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "brand.500",
        textDecoration: "underline",
        fontWeight: "600",
      },
    },
  },
});
