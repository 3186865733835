import { Box } from "@chakra-ui/react";
import React from "react";

export const PolicySticker = ({
  firstText,
  secondText,
}: {
  firstText: string;
  secondText: string;
}) => (
  <Box>
    <Box display={["block", "none"]} mb="15px" mr="20px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67.668"
        height="66.758"
        viewBox="0 0 67.668 66.758"
      >
        <g transform="translate(-2856.249 -3497.067)">
          <path
            d="M82.21,87.787,38.377,83.952a10.011,10.011,0,0,1-9.09-10.833l2.964-33.873L53.917,21.067,87.788,24.03a10.012,10.012,0,0,1,9.09,10.834L93.043,78.7a10.014,10.014,0,0,1-9.954,9.128Q82.652,87.825,82.21,87.787Z"
            transform="translate(2827 3476)"
            fill="#005802"
          />
          <path
            d="M45,68.317a2,2,0,0,1-.863-2.686c1.786-3.476,9.262-12.488,24.917-11.119a1,1,0,1,0,.175-1.993c-8.132-.711-14.079,1.2-18.3,3.7a12.267,12.267,0,0,1,.019-1.284,12,12,0,0,1,13-10.907l4.981.437a10.722,10.722,0,0,0,9.811-4.631.972.972,0,0,1,1.756.229,22.715,22.715,0,0,1,1.067,9.164c-.939,10.732-8.586,18.9-18.425,18.751a11.975,11.975,0,0,1-10.47-5.759A16.447,16.447,0,0,0,47.7,67.468a2.011,2.011,0,0,1-1.778,1.075A1.987,1.987,0,0,1,45,68.317Z"
            transform="translate(2827 3476)"
            fill="#fff"
          />
          <path
            d="M42.211,40.119l-9.962-.872,21.667-18.18-.872,9.961a10,10,0,0,1-9.952,9.129Q42.655,40.158,42.211,40.119Z"
            transform="translate(2827 3476)"
            fill="#fff"
            opacity="0.75"
          />
        </g>
      </svg>
    </Box>

    <Box display={["none", "block"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200.165"
        height="100.274"
        viewBox="0 0 200.165 100.274"
      >
        <g transform="translate(-2605.251 -3480.725)">
          <path
            d="M4.251,129l7.183-82.1L33.1,28.726,204.415,43.714V129Z"
            transform="translate(2601 3452)"
            fill="#005802"
          />
          <text
            transform="matrix(0.996, 0.087, -0.087, 0.996, 2631.137, 3514.596)"
            fill="#fff"
            fontSize="18"
            fontWeight="700"
          >
            <tspan x="0" y="0">
              {firstText}
            </tspan>
            <tspan x="0" y="22">
              {secondText}
            </tspan>
          </text>
          <path
            d="M124.245,113.72a3.562,3.562,0,0,1-1.534-4.782c3.174-6.192,16.469-22.248,44.3-19.813a1.781,1.781,0,0,0,.311-3.548c-14.457-1.265-25.029,2.146-32.535,6.6a21.865,21.865,0,0,1,.033-2.288,21.358,21.358,0,0,1,23.117-19.431l8.856.774a19.049,19.049,0,0,0,17.443-8.25,1.727,1.727,0,0,1,3.121.407,40.54,40.54,0,0,1,1.9,16.323c-1.673,19.117-15.271,33.66-32.762,33.4a21.275,21.275,0,0,1-18.612-10.255,29.27,29.27,0,0,0-8.838,9.35,3.574,3.574,0,0,1-3.16,1.916A3.522,3.522,0,0,1,124.245,113.72Z"
            transform="translate(2601 3452)"
            fill="#fff"
          />
          <path
            d="M21.4,47.777l-9.962-.871L33.1,28.725l-.871,9.962a10,10,0,0,1-9.95,9.129Q21.84,47.816,21.4,47.777Z"
            transform="translate(2601 3452)"
            fill="#fff"
            opacity="0.75"
          />
        </g>
      </svg>
    </Box>
  </Box>
);
