import * as React from "react";
import {
  Box,
  Flex,
  Input,
  Checkbox,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { Link } from "react-router-dom";
import { useStore } from "../store";
import { useQuery } from "react-query";
import Api from "../services/api";
import { Production } from "../types";
import { translate } from "../services/translation";

export const Productions = () => {
  const { user, t } = useStore();
  const toast = useToast();
  const { isLoading, error, data, isFetched } = useQuery(
    ["myProductions"],
    () => Api.myProductions(user._id),
    {
      retry: false,
    }
  ) as {
    error: String;
    isLoading: boolean;
    data: any;
    isFetched: boolean;
  };
  const productions = data?.data.elements as Production[];
  React.useEffect(() => {
    if (error) {
      toast({
        title: error,
        status: "error",
        duration: 1500,
        isClosable: true,
        position: "top",
      });
    }
  }, [error]);

  return (
    <Wrapper>
      <Text fontSize="14px" color="rgba(25,25,25,.75)">
        {t("assigned_productions")}
      </Text>
      {isLoading && (
        <Center>
          <Spinner size="xl" color="brandGreen" />
        </Center>
      )}
      {!isLoading && isFetched && productions && productions.length === 0 && (
        <Text my="20px">{t("no_productions")}</Text>
      )}
      {!isLoading &&
        isFetched &&
        productions &&
        productions.map((p: Production) => (
          <Link key={p._id} to={"/production/" + p._id}>
            <ProductionPreview {...p} />
          </Link>
        ))}
    </Wrapper>
  );
};

const ProductionStatus = (props: { status: string }) => {
  const { statuses } = useStore();
  const statusesTranslated = translate(statuses);

  const statusColor = React.useMemo(() => {
    switch (props.status) {
      case "1a5e8a90-7273-437b-a420-00541a1ae35b":
        return "brandGreen.500";
      case "5edfbe8d-247c-4007-bbe3-8f0b2876de4c":
        return "gray.500";
      case "a4261872-2968-4686-b278-beb25f8e65a7":
        return "brand.500";
    }
  }, []);
  const StatusIcon = React.useCallback(() => {
    switch (props.status) {
      case "1a5e8a90-7273-437b-a420-00541a1ae35b":
        return (
          <Box mr="4px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <g transform="translate(-310 -380)">
                <g
                  transform="translate(310 380)"
                  fill="#fff"
                  stroke="#005802"
                  strokeWidth="1"
                  opacity="0"
                >
                  <rect width="12" height="12" stroke="none" />
                  <rect x="0.5" y="0.5" width="11" height="11" fill="none" />
                </g>
                <g transform="translate(36.5 -94.5)">
                  <line
                    x1="5"
                    y2="5"
                    transform="translate(278 477.5)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    x2="2"
                    y2="2"
                    transform="translate(276 480.5)"
                    fill="none"
                    stroke="#005802"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </Box>
        );
      case "5edfbe8d-247c-4007-bbe3-8f0b2876de4c":
        return (
          <Box mr="4px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <g transform="translate(-298 -380)">
                <g
                  transform="translate(298 380)"
                  fill="#191919"
                  stroke="rgba(25,25,25,.5)"
                  strokeWidth="1"
                  opacity="0"
                >
                  <rect width="12" height="12" stroke="none" />
                  <rect x="0.5" y="0.5" width="11" height="11" fill="none" />
                </g>
                <g transform="translate(257 155.036)">
                  <line
                    y2="6"
                    transform="translate(49 227.714)"
                    fill="none"
                    stroke="rgba(25,25,25,.5)"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    y2="6"
                    transform="translate(45 227.714)"
                    fill="none"
                    stroke="rgba(25,25,25,.5)"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </Box>
        );
      default:
      case "a4261872-2968-4686-b278-beb25f8e65a7":
        return (
          <Box h="8px" w="8px" mr="4px" borderRadius="4px" bg={statusColor} />
        );
    }
  }, []);

  return (
    <Flex alignItems="center">
      <StatusIcon />
      <Text color={statusColor} fontSize="12px" fontWeight="bold">
        {statusesTranslated[props.status]}
      </Text>
    </Flex>
  );
};

const ProductionPreview = (p: Production) => {
  return (
    <Box
      p="16px 20px"
      width="100%"
      borderBottom="1px solid #EAEBEB"
      position="relative"
      overflow="hidden"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <ProductionStatus status={p.status} />
          <Text fontSize="18px" fontWeight="bold">
            {translate(p.title)}
          </Text>
          <Text color="rgba(25,25,25,.5)" fontSize="14px" fontWeight="light">
            {translate(p.subtitle)}
          </Text>
          <Text color="rgba(25,25,25,.75)" fontSize="14px">
            <b>{p.Documents.length}</b> files
          </Text>
        </Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g transform="translate(-322 -266)" opacity="0.498">
            <g
              transform="translate(322 266)"
              fill="none"
              stroke="rgba(25,25,25,.5)"
              strokeWidth="1"
              opacity="0"
            >
              <rect width="24" height="24" stroke="none" />
              <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path
              d="M-364.052,358.307l-6,6,6,6"
              transform="translate(-32.052 642.307) rotate(180)"
              fill="none"
              stroke="rgba(25,25,25,.5)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      </Flex>
      <Box position="absolute" bottom="-30px" right="50px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97.98"
          height="97.98"
          viewBox="0 0 97.98 97.98"
        >
          <g transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 20.706)">
            <path d="M0,0H80V80H0Z" fill="none" />
            <path
              d="M50.667,3V9.667H44V3H17.333V9.667H10.667V3H4V63h6.667V56.333h6.667V63H44V56.333h6.667V63h6.667V3ZM17.333,49.667H10.667V43h6.667Zm0-13.333H10.667V29.667h6.667Zm0-13.333H10.667V16.333h6.667Zm20,33.333H24V9.667H37.333Zm13.333-6.667H44V43h6.667Zm0-13.333H44V29.667h6.667Zm0-13.333H44V16.333h6.667Z"
              transform="translate(9.333 7)"
              fill="#191919"
            />
          </g>
        </svg>
      </Box>
    </Box>
  );
};
