import * as React from "react";
import {
  Box,
  Flex,
  Text,
  Grid,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { Link, useParams } from "react-router-dom";
import Api from "../services/api";
import { useStore } from "../store";
import { Document, Production } from "../types";
import { useQuery } from "react-query";
import { getDateString } from "../services/utils";
import { translate } from "../services/translation";
import { FileBox } from "../components/file-box";

export const Archive = () => {
  const { t } = useStore();
  const { id, folder } = useParams<{ id: string; folder: string }>();
  const toast = useToast();
  const { isLoading, error, data, isFetched } = useQuery(
    ["myProductions"],
    () => Api.production(id),
    {
      retry: false,
    }
  ) as {
    error: String;
    isLoading: boolean;
    data: any;
    isFetched: boolean;
  };
  const production = data?.data as Production;

  React.useEffect(() => {
    if (error) {
      toast({
        title: error,
        status: "error",
        duration: 1500,
        isClosable: true,
        position: "top",
      });
    }
  }, [error]);

  const documents = React.useMemo(
    () =>
      (isFetched && production?.Documents
        ? production.Documents.reduce((acc: any, next: Document) => {
            if (next.folder === folder)
              if (acc[next.folder]) {
                acc[next.folder].push(next);
              } else {
                acc[next.folder] = [next];
              }
            return acc;
          }, {})
        : {})[folder ? folder : ""] || [],
    [production]
  );

  const folders: { [key: string]: string } = {
    "0b902574-8d45-43ad-b14d-fa467d112d51": "Stralcio",
    "0c2d72d4-b50d-4e1a-8e0e-b94363f68644": "PDL",
    "325a73d2-d7c1-4060-a178-318316dd9163": "Sceneggiatura",
    "b1da60ef-d050-4c53-a753-393a51e8aee3": "ODG",
  };

  if (!id || !folder) return null;
  return (
    <Wrapper>
      <Text fontSize="14px" mb="24px" color="rgba(25,25,25,.75)">
        {folders[folder]}
      </Text>
      {isLoading && (
        <Center>
          <Spinner size="xl" color="brandGreen" />
        </Center>
      )}
      {!isLoading && isFetched && documents && documents.length === 0 && (
        <Text my="20px">{t("no_documents")}</Text>
      )}
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}>
        {!isLoading &&
          isFetched &&
          documents &&
          documents.map((d: Document) => (
            <FileBox
              type={folders[folder]}
              key={d._id}
              url={"https://db.lacasarossa.productions/api/v1/" + d?.file?.url}
              name={translate(d.name)}
              description={getDateString(d.date)}
            />
          ))}
      </Grid>
    </Wrapper>
  );
};
