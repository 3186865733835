import * as React from "react";
import { useStore } from "../store";
import { withServiceWorkerUpdater } from "@3m1/service-worker-updater";

const Updater = (props: any) => {
  const { t } = useStore();
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  React.useEffect(() => {
    if (newServiceWorkerDetected && window.confirm(t("update_available"))) {
      onLoadNewServiceWorkerAccept();
    }
  }, [newServiceWorkerDetected]);
  return null;
};

export default withServiceWorkerUpdater(Updater);
