import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import { translate, translations } from "./services/translation";
import { Status, User } from "./types";

export const useStore = create<{
  appInstaller: any;
  setAppInstaller: (installer: any) => void;
  ambientalPolicyFiles: any[];
  setAmbientalPolicy: (files: any[]) => void;
  user: User;
  setUser: (user: User) => void;
  firstTime: boolean;
  lang: string;
  statuses?: Status[];
  setStatuses: (statuses: Status[]) => void;
  setLang: (lang: string) => void;
  setFirstTime: (isFirstTime: boolean) => void;
  t: (key: string) => string;
}>(
  persist(
    devtools((set, get) => ({
      user: {},
      appInstaller: null,
      setAppInstaller: (appInstaller) => set({ appInstaller }),
      firstTime: true,
      statuses: [],
      ambientalPolicyFiles: [],
      lang: "it",
      setAmbientalPolicy: (files) => set({ ambientalPolicyFiles: files }),
      setStatuses: (statuses: Status[]) => set({ statuses }),
      setLang: (lang) => set({ lang }),
      setUser: (user) => set({ user }),
      setFirstTime: (firstTime) => set({ firstTime }),
      t: (key: string): string => translate(translations)[key] || key,
    })),
    {
      name: "app-storage",
    }
  )
);
