import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

const currentBorder = "6px solid rgba(0,88,2,.1)";
export const LanguageSelector = ({
  lang,
  onSelect,
}: {
  lang: string;
  onSelect: (lang: string) => void;
}) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Box
      onClick={() => onSelect("it")}
      cursor="pointer"
      mx="6px"
      borderRadius="6px"
      px={lang === "it" ? "0px" : "6px"}
      border={lang === "it" ? currentBorder : ""}
    >
      {itaFlag}
    </Box>
    <Box
      onClick={() => onSelect("en")}
      cursor="pointer"
      px={lang === "en" ? "0px" : "6px"}
      borderRadius="6px"
      border={lang === "en" ? currentBorder : ""}
    >
      {engFlag}
    </Box>
  </Flex>
);

const itaFlag = (
  <svg width="28" height="20" viewBox="0 0 28 20">
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rettangolo_287"
          data-name="Rettangolo 287"
          width="28"
          height="20"
          rx="2"
          transform="translate(294 52)"
          fill="#005802"
          stroke="#005802"
          strokeWidth="1"
          opacity="0.097"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#f0f0f0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e43d4c" />
        <stop offset="1" stopColor="#cc2e3c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#1bb65d" />
        <stop offset="1" stopColor="#139149" />
      </linearGradient>
    </defs>
    <g transform="translate(-294 -52)" clipPath="url(#clipPath)">
      <g id="Symbols" transform="translate(294 52)">
        <g id="IT">
          <rect
            id="FlagBackground"
            width="28"
            height="20"
            fill="url(#linear-gradient)"
          />
          <rect
            id="Mask"
            width="14.667"
            height="20"
            transform="translate(13.333)"
            fill="url(#linear-gradient-2)"
          />
          <rect
            id="Rectangle-2"
            width="9.333"
            height="20"
            fill="url(#linear-gradient-3)"
          />
          <rect
            id="Rectangle-2-Copy"
            width="9.333"
            height="20"
            transform="translate(9.333)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
    </g>
  </svg>
);
const engFlag = (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20">
    <defs>
      <linearGradient id="A" x1=".5" x2=".5" y2="1">
        <stop offset="0" stopColor="#0a17a7" />
        <stop offset="1" stopColor="#030e88" />
      </linearGradient>
      <clipPath id="B">
        <rect
          width="28"
          height="20"
          rx="2"
          fill="#005802"
          stroke="#005802"
          strokeWidth="1"
          opacity=".097"
          x="338"
          y="52"
        />
      </clipPath>
      <clipPath id="C">
        <path
          transform="translate(1949.128 3567.884)"
          fill="url(#A)"
          d="M0 0h28v20H0z"
        />
      </clipPath>
      <linearGradient id="D" x1=".5" x2=".5" y2="1">
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#f0f0f0" />
      </linearGradient>
      <linearGradient id="E" x1=".5" x2=".5" y2="1">
        <stop offset="0" stopColor="#e6273e" />
        <stop offset="1" stopColor="#cf152b" />
      </linearGradient>
    </defs>
    <g transform="translate(-338 -52)" clipPath="url(#B)">
      <g transform="translate(-1611.128 -3515.884)" clipPath="url(#C)">
        <path fill="url(#D)" d="M1949.13 3567.883h28v20h-28z" />
        <path fill="url(#A)" d="M1949.128 3567.883h28v20h-28z" />
        <path
          d="M1955.801 3581.216h-6.673v-6.666h6.673l-9.447-6.373 1.491-2.21 11.949 8.06v-7.477h6.667v7.476l11.949-8.06 1.491 2.211-9.447 6.373h6.674v6.667h-6.674l9.447 6.372-1.491 2.215-11.949-8.06v7.477h-6.667v-7.481l-11.949 8.064-1.491-2.211z"
          fillRule="evenodd"
          fill="url(#D)"
        />
        <g fill="#db1f35">
          <path d="M1967.979 3574.494l12.665-8.332c.075-.048.127-.123.146-.21s.002-.177-.046-.252-.123-.127-.21-.146-.177-.002-.252.046l-12.669 8.337c-.154.101-.197.308-.096.461s.308.197.462.095zm.971 7.363l11.354 7.653c.099.067.226.075.333.023s.178-.158.187-.277-.047-.233-.146-.3l-11.35-7.653c-.153-.103-.36-.063-.463.09s-.063.36.09.463zm-11.63-7.94l-11.843-7.981a.33.33 0 0 0-.25-.051c-.087.017-.163.068-.213.141a.33.33 0 0 0-.051.25c.017.087.068.163.141.213l11.843 7.981c.153.103.36.063.463-.09s.063-.36-.09-.463zm.914 7.293l-13.128 8.706c-.074.049-.125.125-.143.212s0 .177.049.25a.34.34 0 0 0 .212.143c.087.018.177 0 .25-.049l13.128-8.706c.154-.102.196-.308.094-.462s-.308-.196-.462-.094z" />
        </g>
        <path
          d="M1949.13 3579.883h12v8h4v-8h12v-4h-12v-8h-4v8h-12z"
          fillRule="evenodd"
          fill="url(#E)"
        />
      </g>
    </g>
  </svg>
);
