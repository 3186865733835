import React from "react";
import packageJson from "../../package.json";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { PolicySticker } from "./policy-sticker";
import { useStore } from "../store";

export const Footer = () => {
  const { t, firstTime } = useStore();
  if (firstTime) return null;
  return (
    <>
      <Box h="86px" />
      <Box position="absolute" bottom="0" w="100%">
        <Box
          position="absolute"
          width="0"
          height="0"
          right="0"
          borderStyle="solid"
          borderWidth="20px 0 0 100vw"
          borderColor="transparent transparent transparent #EAEBEB"
        />
        <Box
          position="absolute"
          width="0"
          height="0"
          right="0"
          borderStyle="solid"
          borderWidth=" 0 0 20px 100vw"
          borderColor="transparent transparent #AC1926 transparent"
        />
        <Box
          color="#FFF"
          bg="#AC1926"
          mt="20px"
          p={["11px 20px 11px", "21px 20px 21px"]}
          position="relative"
        >
          <Flex alignItems="center">
            <Text>ODG {packageJson.version}</Text>
          </Flex>
          <Box position="absolute" bottom="0" right="0">
            <a rel="noreferrer" href={"/ambiental-policy"}>
              <PolicySticker
                firstText={t("ambiental_policy_1")}
                secondText={t("ambiental_policy_2")}
              />
            </a>
          </Box>
        </Box>
      </Box>
    </>
  );
};
