import * as React from "react";
import {
  Box,
  Flex,
  Input,
  Checkbox,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../store";
import Api from "../services/api";
import { useQuery } from "react-query";
import { Document, Production as ProductionType } from "../types";
import { translate } from "../services/translation";
import { getDateString } from "../services/utils";

function sameDay(day1: string, day2: string | undefined) {
  const d1 = new Date(day1);
  const d2 = day2 ? new Date(day2) : new Date();

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export const Production = () => {
  const { t } = useStore();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { isLoading, error, data, isFetched } = useQuery(
    ["myProductions"],
    () => Api.production(id),
    {
      retry: false,
    }
  ) as {
    error: String;
    isLoading: boolean;
    data: any;
    isFetched: boolean;
  };
  const production = data?.data as ProductionType;

  React.useEffect(() => {
    if (error) {
      toast({
        title: error,
        status: "error",
        duration: 1500,
        isClosable: true,
        position: "top",
      });
    }
  }, [error]);

  const productionCategoriesCount = React.useMemo(
    () =>
      isFetched && production?.Documents
        ? production.Documents.reduce((acc: any, next: Document) => {
            if (acc[next.folder]) {
              acc[next.folder]++;
            } else {
              acc[next.folder] = 1;
            }
            return acc;
          }, {})
        : {},
    [production]
  );

  const folders = {
    Stralcio: "0b902574-8d45-43ad-b14d-fa467d112d51",
    PDL: "0c2d72d4-b50d-4e1a-8e0e-b94363f68644",
    Sceneggiatura: "325a73d2-d7c1-4060-a178-318316dd9163",
    ODG: "b1da60ef-d050-4c53-a753-393a51e8aee3",
  };

  const todayODG = React.useMemo(
    () =>
      isFetched && production?.Documents
        ? production.Documents.find((next: Document) =>
            sameDay(next.date, undefined)
          )
        : null,
    [production, isFetched]
  );

  return (
    <Wrapper>
      {isLoading && (
        <Center>
          <Spinner size="xl" color="brandGreen" />
        </Center>
      )}
      {!isLoading && production && (
        <>
          <Text fontSize="14px" mb="24px" color="rgba(25,25,25,.75)">
            {translate(production.title)}
          </Text>
          {todayODG && (
            <Link
              to={
                "/document/" +
                encodeURIComponent(
                  "https://db.lacasarossa.productions/api/v1/" +
                    todayODG?.file?.url
                )
              }
            >
              <Box
                p="16px 20px"
                width="100%"
                borderBottom="1px solid #EAEBEB"
                position="relative"
                overflow="hidden"
                bg="#191919"
                borderRadius="4px"
                color="#FFF"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Box flex="1" textAlign="center">
                    <Text fontSize="22px" fontWeight="semibold">
                      {translate(todayODG.name)}
                    </Text>
                    <Text color="#FFFFFF" fontSize="14px" fontWeight="light">
                      {getDateString(todayODG.date)}
                    </Text>
                  </Box>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="Raggruppa_98"
                      data-name="Raggruppa 98"
                      transform="translate(-299 -128)"
                    >
                      <g
                        id="Rettangolo_179"
                        data-name="Rettangolo 179"
                        transform="translate(299 128)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                        opacity="0.003"
                      >
                        <rect width="24" height="24" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          fill="none"
                        />
                      </g>
                      <line
                        id="Linea_6"
                        data-name="Linea 6"
                        x1="6"
                        transform="translate(304 133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_15"
                        data-name="Linea 15"
                        y2="6"
                        transform="translate(318 141)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_16"
                        data-name="Linea 16"
                        y2="4"
                        transform="translate(318 133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_8"
                        data-name="Linea 8"
                        y2="14"
                        transform="translate(304 133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_7"
                        data-name="Linea 7"
                        x1="14"
                        transform="translate(304 147)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_17"
                        data-name="Linea 17"
                        x1="4"
                        transform="translate(314 133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Linea_18"
                        data-name="Linea 18"
                        x1="6"
                        y2="6"
                        transform="translate(312 133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </Flex>
                <Box position="absolute" bottom="-20px" left="50px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                  >
                    <g
                      id="Raggruppa_151"
                      data-name="Raggruppa 151"
                      transform="translate(-44 -188)"
                    >
                      <g
                        id="insert_drive_file_black_24dp-2"
                        transform="translate(44 188)"
                      >
                        <path
                          id="Tracciato_58"
                          data-name="Tracciato 58"
                          d="M0,0H64V64H0Z"
                          fill="none"
                        />
                        <path
                          id="Tracciato_59"
                          data-name="Tracciato 59"
                          d="M30.667,2H9.333A5.326,5.326,0,0,0,4.027,7.333L4,50a5.326,5.326,0,0,0,5.307,5.333H41.333A5.349,5.349,0,0,0,46.667,50V18ZM9.333,50V7.333H28V20.667H41.333V50Z"
                          transform="translate(6.667 3.333)"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="checklist_black_24dp"
                        transform="translate(60 207)"
                      >
                        <rect
                          id="Rettangolo_161"
                          data-name="Rettangolo 161"
                          width="32"
                          height="32"
                          fill="none"
                        />
                        <path
                          id="Tracciato_7"
                          data-name="Tracciato 7"
                          d="M28.667,8.023h-12V10.69h12Zm0,10.667h-12v2.667h12ZM6.72,13.357,2,8.637l1.88-1.88L6.707,9.583,12.36,3.93l1.88,1.88Zm0,10.667L2,19.3l1.88-1.88L6.707,20.25,12.36,14.6l1.88,1.88Z"
                          transform="translate(0.667 1.31)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </Box>
              </Box>
            </Link>
          )}
          <Link to={"/production/" + id + "/" + folders["ODG"]}>
            <Box
              p="16px 20px"
              width="100%"
              borderBottom="1px solid #EAEBEB"
              position="relative"
              overflow="hidden"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Box flex="1" textAlign="center">
                  <Text fontSize="22px" fontWeight="semibold">
                    {t("odg_archive")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="rgba(25,25,25,.5)"
                    fontWeight="light"
                  >
                    <b>{productionCategoriesCount[folders["ODG"]] || 0}</b>{" "}
                    files
                  </Text>
                </Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="Raggruppa_93"
                    data-name="Raggruppa 93"
                    transform="translate(-322 -266)"
                    opacity="0.497"
                  >
                    <g
                      id="Rettangolo_178"
                      data-name="Rettangolo 178"
                      transform="translate(322 266)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="1"
                      opacity="0"
                    >
                      <rect width="24" height="24" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Tracciato_18"
                      data-name="Tracciato 18"
                      d="M-364.052,358.307l-6,6,6,6"
                      transform="translate(-32.052 642.307) rotate(180)"
                      fill="none"
                      stroke="#191919"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </Flex>
              <Box position="absolute" bottom="-20px" left="50px">
                <svg
                  id="folder_open_black_24dp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                >
                  <path
                    id="Tracciato_52"
                    data-name="Tracciato 52"
                    d="M0,0H64V64H0Z"
                    fill="none"
                  />
                  <path
                    id="Tracciato_53"
                    data-name="Tracciato 53"
                    d="M50,9.333H28.667L23.333,4h-16A5.326,5.326,0,0,0,2.027,9.333L2,41.333a5.349,5.349,0,0,0,5.333,5.333H50a5.349,5.349,0,0,0,5.333-5.333V14.667A5.349,5.349,0,0,0,50,9.333Zm0,32H7.333V14.667H50Z"
                    transform="translate(3.333 6.667)"
                    fill="#191919"
                  />
                </svg>
              </Box>
            </Box>
          </Link>
          <Link to={"/production/" + id + "/" + folders["PDL"]}>
            <Box
              p="16px 20px"
              width="100%"
              borderBottom="1px solid #EAEBEB"
              position="relative"
              overflow="hidden"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Box flex="1" textAlign="center">
                  <Text fontSize="22px" fontWeight="semibold">
                    {t("PDL")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="rgba(25,25,25,.5)"
                    fontWeight="light"
                  >
                    <b>{productionCategoriesCount[folders["PDL"]] || 0}</b>{" "}
                    files
                  </Text>
                </Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="Raggruppa_93"
                    data-name="Raggruppa 93"
                    transform="translate(-322 -266)"
                    opacity="0.497"
                  >
                    <g
                      id="Rettangolo_178"
                      data-name="Rettangolo 178"
                      transform="translate(322 266)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="1"
                      opacity="0"
                    >
                      <rect width="24" height="24" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Tracciato_18"
                      data-name="Tracciato 18"
                      d="M-364.052,358.307l-6,6,6,6"
                      transform="translate(-32.052 642.307) rotate(180)"
                      fill="none"
                      stroke="#191919"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </Flex>
              <Box position="absolute" bottom="-20px" left="50px">
                <svg
                  id="date_range_black_24dp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                >
                  <path
                    id="Tracciato_70"
                    data-name="Tracciato 70"
                    d="M0,0H64V64H0Z"
                    fill="none"
                  />
                  <path
                    id="Tracciato_71"
                    data-name="Tracciato 71"
                    d="M13.667,26H19v5.333H13.667ZM51,12.667V50a5.349,5.349,0,0,1-5.333,5.333H8.333A5.332,5.332,0,0,1,3,50l.027-37.333A5.309,5.309,0,0,1,8.333,7.333H11V2h5.333V7.333H37.667V2H43V7.333h2.667A5.349,5.349,0,0,1,51,12.667ZM8.333,18H45.667V12.667H8.333ZM45.667,50V23.333H8.333V50ZM35,31.333h5.333V26H35Zm-10.667,0h5.333V26H24.333Z"
                    transform="translate(5 3.333)"
                    fill="#191919"
                  />
                </svg>
              </Box>
            </Box>
          </Link>
          <Link to={"/production/" + id + "/" + folders["Sceneggiatura"]}>
            <Box
              p="16px 20px"
              width="100%"
              borderBottom="1px solid #EAEBEB"
              position="relative"
              overflow="hidden"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Box flex="1" textAlign="center">
                  <Text fontSize="22px" fontWeight="semibold">
                    {t("film_scripts")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="rgba(25,25,25,.5)"
                    fontWeight="light"
                  >
                    <b>
                      {productionCategoriesCount[folders["Sceneggiatura"]] || 0}
                    </b>{" "}
                    files
                  </Text>
                </Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="Raggruppa_93"
                    data-name="Raggruppa 93"
                    transform="translate(-322 -266)"
                    opacity="0.497"
                  >
                    <g
                      id="Rettangolo_178"
                      data-name="Rettangolo 178"
                      transform="translate(322 266)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="1"
                      opacity="0"
                    >
                      <rect width="24" height="24" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Tracciato_18"
                      data-name="Tracciato 18"
                      d="M-364.052,358.307l-6,6,6,6"
                      transform="translate(-32.052 642.307) rotate(180)"
                      fill="none"
                      stroke="#191919"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </Flex>
              <Box position="absolute" bottom="-20px" left="50px">
                <svg
                  id="movie_black_24dp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                >
                  <path
                    id="Tracciato_10"
                    data-name="Tracciato 10"
                    d="M0,0H64V64H0Z"
                    fill="none"
                  />
                  <path
                    id="Tracciato_11"
                    data-name="Tracciato 11"
                    d="M7.333,10.587,12.027,20H50V41.333H7.333V10.587M55.333,4H44.667L50,14.667H42L36.667,4H31.333l5.333,10.667h-8L23.333,4H18l5.333,10.667h-8L10,4H7.333A5.326,5.326,0,0,0,2.027,9.333L2,41.333a5.349,5.349,0,0,0,5.333,5.333H50a5.349,5.349,0,0,0,5.333-5.333Z"
                    transform="translate(3.333 6.667)"
                    fill="#191919"
                  />
                </svg>
              </Box>
            </Box>
          </Link>
          <Link to={"/production/" + id + "/" + folders["Stralcio"]}>
            <Box
              p="16px 20px"
              width="100%"
              borderBottom="1px solid #EAEBEB"
              position="relative"
              overflow="hidden"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Box flex="1" textAlign="center">
                  <Text fontSize="22px" fontWeight="semibold">
                    {t("exerpts")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="rgba(25,25,25,.5)"
                    fontWeight="light"
                  >
                    <b>{productionCategoriesCount[folders["Stralcio"]] || 0}</b>{" "}
                    files
                  </Text>
                </Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="Raggruppa_93"
                    data-name="Raggruppa 93"
                    transform="translate(-322 -266)"
                    opacity="0.497"
                  >
                    <g
                      id="Rettangolo_178"
                      data-name="Rettangolo 178"
                      transform="translate(322 266)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="1"
                      opacity="0"
                    >
                      <rect width="24" height="24" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Tracciato_18"
                      data-name="Tracciato 18"
                      d="M-364.052,358.307l-6,6,6,6"
                      transform="translate(-32.052 642.307) rotate(180)"
                      fill="none"
                      stroke="#191919"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </Flex>
              <Box position="absolute" bottom="-20px" left="50px">
                <svg
                  id="description_black_24dp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                >
                  <path
                    id="Tracciato_68"
                    data-name="Tracciato 68"
                    d="M0,0H64V64H0Z"
                    fill="none"
                  />
                  <path
                    id="Tracciato_69"
                    data-name="Tracciato 69"
                    d="M14.667,39.333H36v5.333H14.667Zm0-10.667H36V34H14.667ZM30.667,2H9.333A5.349,5.349,0,0,0,4,7.333V50a5.326,5.326,0,0,0,5.307,5.333H41.333A5.349,5.349,0,0,0,46.667,50V18ZM41.333,50h-32V7.333H28V20.667H41.333Z"
                    transform="translate(6.667 3.333)"
                    fill="#191919"
                  />
                </svg>
              </Box>
            </Box>
          </Link>
        </>
      )}
    </Wrapper>
  );
};
