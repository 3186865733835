import { Text } from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { useStore } from "../store";
import pwa_ios_guide_1 from "../assets/ios_guide1.png";
import pwa_ios_guide_2 from "../assets/ios_guide2.png";

export const PwaGuideiOS = () => {
  const { t } = useStore();

  return (
    <Wrapper>
      <Text fontSize="14px" mb="24px" color="rgba(25,25,25,.75)">
        {t("pwa_ios_guide_title")}
      </Text>
      <Text my="20px">{t("pwa_ios_guide_1")}</Text>
      <img src={pwa_ios_guide_1} alt="" />
      <Text my="20px">{t("pwa_ios_guide_2")}</Text>
      <img src={pwa_ios_guide_2} alt="" />
    </Wrapper>
  );
};
