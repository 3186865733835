import * as React from "react";
import { Text, Grid } from "@chakra-ui/react";
import { Wrapper } from "../components/wrapper";
import { useStore } from "../store";
import { FileBox } from "../components/file-box";

export const AmbientalPolicy = () => {
  const { t, ambientalPolicyFiles } = useStore();

  return (
    <Wrapper>
      <Text fontSize="14px" mb="24px" color="rgba(25,25,25,.75)">
        {t("ambiental_policy_1")} {t("ambiental_policy_2")}
      </Text>
      {ambientalPolicyFiles && ambientalPolicyFiles.length === 0 && (
        <Text my="20px">{t("no_documents")}</Text>
      )}
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}>
        {ambientalPolicyFiles &&
          ambientalPolicyFiles.map((d: any) => {
            const paths = d?.url.split("/");
            const fileName = paths[paths.length - 1];
            return (
              <FileBox
                key={d._id}
                type="AmbientalPolicy"
                url={"https://db.lacasarossa.productions/api/v1/" + d?.url}
                name={fileName}
                description={`${t("ambiental_policy_1")} ${t(
                  "ambiental_policy_2"
                )}`}
              />
            );
          })}
      </Grid>
    </Wrapper>
  );
};
