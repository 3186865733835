import axios from "axios";

import { useStore } from "../store";

import { Options } from "./api.service.d";

axios.defaults.headers.common.Accept = "application/json";
axios.defaults.baseURL = "https://db.lacasarossa.productions/api/v1";

const link =
  window.location.protocol +
  "//" +
  window.location.hostname +
  "/password-reset";

class Api {
  static _temporaryToken: string | undefined = undefined;

  static request = async (
    method: any,
    url: string,
    params: any = {},
    headers: any = {},
    data: any = {},
    useToken: boolean
  ) => {
    if (useToken) {
      const token = Api._temporaryToken
        ? Api._temporaryToken
        : useStore.getState().user.token;
      headers.Authorization = `Bearer ${token}`;
    }

    return axios({ method, url, params, headers, data });
  };

  static postImage = async (url: string, data: any = {}, useToken = false) => {
    const headers: any = { "Content-Type": "multipart/form-data" };

    if (useToken) {
      const token = Api._temporaryToken
        ? Api._temporaryToken
        : useStore.getState().user.token;
      headers.Authorization = `Bearer ${token}`;
    }

    return axios({
      url,
      data,
      method: "POST",
      headers,
    });
  };
  static putImage = async (url: string, data: any = {}, useToken = false) => {
    const headers: any = { "Content-Type": "multipart/form-data" };

    if (useToken) {
      const token = Api._temporaryToken
        ? Api._temporaryToken
        : useStore.getState().user.token;
      headers.Authorization = `Bearer ${token}`;
    }

    return axios({
      url,
      data,
      method: "PUT",
      headers,
    });
  };

  static get = async (url: string, opt: Options, useToken = false) =>
    Api.request("get", url, opt.params, opt.headers, opt.data, useToken);
  static post = async (url: string, opt: Options, useToken = false) =>
    Api.request("post", url, opt.params, opt.headers, opt.data, useToken);
  static delete = async (url: string, opt: Options, useToken = false) =>
    Api.request("delete", url, opt.params, opt.headers, opt.data, useToken);
  static patch = async (url: string, opt: Options, useToken = false) =>
    Api.request("patch", url, opt.params, opt.headers, opt.data, useToken);
  static put = async (url: string, opt: Options, useToken = false) =>
    Api.request("put", url, opt.params, opt.headers, opt.data, useToken);

  // App requests
  static login = async (email: string, password: string) =>
    Api.post("/public/auth", { data: { username: email, password } });
  static forgotPassword = async (email: string) =>
    Api.post("/public/forgot-password", {
      data: { email, link },
    });
  static resetPassword = async (password: string, code: string) =>
    Api.post("/public/reset-password", {
      data: { password, code },
    });

  static myProductions = async (id?: string) =>
    Api.post(
      "table/Production/list?translate=false",
      {
        data: {
          options: {
            populate: [{ path: "Documents" }],
          },
          filter: {
            users: id,
          },
        },
      },
      true
    );
  static production = async (id?: string) =>
    Api.post(
      "table/Production/find?translate=true",
      {
        data: {
          options: {
            populate: [{ path: "Documents" }],
          },
          filter: {
            _id: id,
          },
        },
      },
      true
    );
  static ambientalPolicy = async () =>
    Api.post("table/AmbientalPolicy/find", {}, true);
  static statusesSchema = async () => Api.get("schema/Production", {}, true);

  static currentProfile = async () => Api.get("/user/current", {}, true);
  static editAvatar = async (data = {}) =>
    Api.putImage("/user/current/avatar", data, true);
  static notifications = async () =>
    Api.get("/user/current/notifications", {}, true);
  static readNotification = async (id: string) =>
    Api.put("/user/current/notifications/" + id + "/read", {}, true);
  static profile = async (id: string) => Api.get("/user/" + id, {}, true);
  static event = async (id: string) => Api.get("/events/" + id, {}, true);
  static places = async (inputText: string) =>
    Api.get("/places/", { params: { inputText } }, true);
  static place = async (placeId: string | number) =>
    Api.get("/places/" + placeId, {}, true);
  static placesFromCoordinates = async (
    lat: string | number,
    lon: string | number
  ) => Api.get("/places/fromCoordinates", { params: { lat, lon } }, true);
  static approveAttendance = async (
    id: string,
    attendanceId: string,
    attendanceCode: string
  ) =>
    Api.put(
      "/events/" + id + "/attendances/approve",
      {
        data: {
          attendanceId,
          attendanceCode,
        },
      },
      true
    );
  static attendancedEvents = async (id: string, params: {}) =>
    Api.get("/user/" + id + "/attendancedEvents", { params }, true);
  static organizedEvents = async (id: string, params: {}) =>
    Api.get("/user/" + id + "/organizedEvents", { params }, true);
  static searchEvents = async (params = {}) =>
    Api.get("/events/search", { params }, true);
  static categories = async () => Api.get("/categories", {}, true);
  static createEvent = async (data = {}) =>
    Api.postImage("/events", data, true);
  static createAttendance = async (eventId: string) =>
    Api.post("/attendances/", { data: { eventId } }, true);
  static deleteEvent = async (eventId: string) =>
    Api.delete("/events/" + eventId, {}, true);

  static facebookLogin = async (token: string) =>
    Api.post("/user/login/facebook", { data: { token } });
  static signUp = async (data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    birthdate: Date;
  }) => Api.post("/user/register/email", { data });
  static resetTemporaryToken = () => {
    Api._temporaryToken = "";
  };
  static setTemporaryToken = (value: any) => {
    Api._temporaryToken = value;
  };
  static editUser = async (data: any, useToken: boolean) => {
    Api.put("/user/current", { data }, useToken);
  };
  static on401 = (callback: any) => {
    return axios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        const { response } = error;
        if (response && response.status === 401) callback(response);
        return Promise.reject(error);
      }
    );
  };
}

export default Api;
