import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const FileBox = (props: {
  url: string;
  name: string;
  type: string;
  description: string;
}) => {
  const Icon = () => {
    switch (props.type) {
      case "AmbientalPolicy":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <rect
              id="Rettangolo_240"
              data-name="Rettangolo 240"
              width="42"
              height="42"
              rx="4"
              fill="#eaebeb"
              opacity="0.252"
            />
            <g id="movie_black_24dp" transform="translate(9 9)" opacity="0.75">
              <path
                id="Tracciato_10"
                data-name="Tracciato 10"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Icon_awesome-leaf"
                data-name="Icon awesome-leaf"
                d="M18.472-1a1.532,1.532,0,0,1,1.41.929A13.554,13.554,0,0,1,21,5.356a13.249,13.249,0,0,1-2.824,8.361,10.436,10.436,0,0,1-7.376,3.9,7.969,7.969,0,0,1-.822.043A7.661,7.661,0,0,1,4.56,15.384a7.671,7.671,0,0,0-1.493,2.092,2.113,2.113,0,0,1-3.909-1.6,11.9,11.9,0,0,1,3.5-4.478,1,1,0,0,1-.266-.582l-.008-.081A6.888,6.888,0,0,1,2.336,10,7.675,7.675,0,0,1,10,2.332h2.778A4.967,4.967,0,0,0,17.12-.2,1.548,1.548,0,0,1,18.472-1ZM9.981,15.665a5.963,5.963,0,0,0,.628-.034l.033,0C15.487,15.276,19,10.956,19,5.356a11.982,11.982,0,0,0-.637-3.824,6.919,6.919,0,0,1-5.586,2.8H10A5.677,5.677,0,0,0,4.449,8.867a18.38,18.38,0,0,1,8.888-2.091,1.555,1.555,0,1,1,0,3.111,14.63,14.63,0,0,0-8.992,2.726,1.846,1.846,0,0,1,1.1.738A5.725,5.725,0,0,0,9.981,15.665Z"
                transform="translate(1.996 3.113)"
                fill="#005802"
              />
            </g>
          </svg>
        );
      case "Stralcio":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <rect
              id="Rettangolo_239"
              data-name="Rettangolo 239"
              width="42"
              height="42"
              rx="4"
              fill="#eaebeb"
              opacity="0.252"
            />
            <g
              id="description_black_24dp"
              transform="translate(9 9)"
              opacity="0.75"
            >
              <path
                id="Tracciato_68"
                data-name="Tracciato 68"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Tracciato_69"
                data-name="Tracciato 69"
                d="M8,16h8v2H8Zm0-4h8v2H8ZM14,2H6A2.006,2.006,0,0,0,4,4V20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5Z"
                transform="translate(0 0)"
                fill="#005802"
              />
            </g>
          </svg>
        );
      case "PDL":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <rect
              id="Rettangolo_237"
              data-name="Rettangolo 237"
              width="42"
              height="42"
              rx="4"
              fill="#eaebeb"
              opacity="0.252"
            />
            <g
              id="date_range_black_24dp"
              transform="translate(9 9)"
              opacity="0.75"
            >
              <path
                id="Tracciato_70"
                data-name="Tracciato 70"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Tracciato_71"
                data-name="Tracciato 71"
                d="M7,11H9v2H7ZM21,6V20a2.006,2.006,0,0,1-2,2H5a2,2,0,0,1-2-2L3.01,6A1.991,1.991,0,0,1,5,4H6V2H8V4h8V2h2V4h1A2.006,2.006,0,0,1,21,6ZM5,8H19V6H5ZM19,20V10H5V20Zm-4-7h2V11H15Zm-4,0h2V11H11Z"
                transform="translate(0 0)"
                fill="#005802"
              />
            </g>
          </svg>
        );

      case "Sceneggiatura":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <rect
              id="Rettangolo_238"
              data-name="Rettangolo 238"
              width="42"
              height="42"
              rx="4"
              fill="#eaebeb"
              opacity="0.252"
            />
            <g id="movie_black_24dp" transform="translate(9 9)" opacity="0.75">
              <path
                id="Tracciato_10"
                data-name="Tracciato 10"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Tracciato_11"
                data-name="Tracciato 11"
                d="M4,6.47,5.76,10H20v8H4V6.47M22,4H18l2,4H17L15,4H13l2,4H12L10,4H8l2,4H7L5,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2Z"
                transform="translate(0 0)"
                fill="#005802"
              />
            </g>
          </svg>
        );

      default:
      case "ODG":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <rect
              id="Rettangolo_218"
              data-name="Rettangolo 218"
              width="42"
              height="42"
              rx="4"
              fill="#eaebeb"
              opacity="0.252"
            />
            <g
              id="insert_drive_file_black_24dp-2"
              transform="translate(9 9)"
              opacity="0.75"
            >
              <path
                id="Tracciato_58"
                data-name="Tracciato 58"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Tracciato_96"
                data-name="Tracciato 96"
                d="M-5768.01-2166a2,2,0,0,1-1.99-2l.01-16a2,2,0,0,1,1.99-2h8l6,6v12a2.006,2.006,0,0,1-2,2Zm.01-2h12v-11h-5v-5h-7Zm1-4.77.705-.705,1.06,1.06,2.12-2.12.7.705-2.82,2.83Zm5.5.77v-1h4.5v1Zm-5.5-4.77.705-.705,1.06,1.06,2.12-2.12.7.7-2.82,2.83Zm5.5.77v-1h4.5v1Z"
                transform="translate(5774 2188)"
                fill="#005802"
              />
            </g>
          </svg>
        );
    }
  };
  return (
    <Link to={"/document/" + encodeURIComponent(props.url)}>
      <Box mb="24px">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Box mr="16px" width="42px" height="42px">
              <Icon />
            </Box>
            <Flex direction="column">
              <Text fontSize="22px" fontWeight="semibold">
                {props.name}
              </Text>
              <Text
                color="rgba(25,25,25,.5)"
                fontSize="14px"
                fontWeight="light"
              >
                {props.description}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Link>
  );
};
